// ==================================== COMPONENTS =========================================
// THIRD PARTY/ CORE COMPONENTS
import React, { useEffect, Suspense, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Skeleton, Spin } from 'antd';
import FeatherIcon from 'feather-icons-react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import moment from 'moment';
import ReactAudioPlayer from 'react-audio-player';

// USER'S COMPONENTS
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main } from '../../styled';
import { ProductDetailsWrapper } from '../Style';
import { Cards } from '../../../components/cards/frame/cards-frame';
import Heading from '../../../components/heading/heading';
import ServicingHistoryTable from './ServicingHistoryTable';
import {
  addNewServicingHistorie,
  deleteServicingHistorie,
  editServicingHistorie,
  getAllServicingHistories,
} from '../../../redux/servicing history/actionCreator';
import { Button } from '../../../components/buttons/buttons';
import CreateServicingHistory from './CreateServicingHistory';
import { deleteCarIssueImage, getAllCars, updateCarImageOrder } from '../../../redux/car/actionCreator';
import CarIssueTable from './CarIssueTable';
import { IMAGE_URL } from '../../../utils/constants';
import CarImageOrder from './CarImageOrder';
import View360 from './View360';

const CarDetail = ({ match }) => {
  const dataIndex = Cookies.get('lang') == 'fr' ? 0 : 1;
  const [state, setState] = useState({
    visible: false,
    orderModalVisible: false,
    index: 0,
    view360ModalVisible: false,
  });
  const dispatch = useDispatch();

  const { cars, isLoader, servicingHistories } = useSelector(state => {
    return {
      cars: state.car.cars,
      isLoader: state.car.loading,
      servicingHistories: state.servicingHistory.histories,
    };
  });

  useEffect(() => {
    if (cars.length === 0) dispatch(getAllCars());
    if (servicingHistories.length === 0) getAllServicingHistories(dispatch);
  }, []);

  let car = cars.find(car => car._id === match.params.id);
  const histories = servicingHistories.filter(history => history.car === match.params.id) || [];

  // For handling the open model
  const showModal = (type = undefined) => {
    if (type === 'order') setState({ ...state, orderModalVisible: true });
    else if (type === '360View') setState({ ...state, view360ModalVisible: true });
    else setState({ ...state, visible: true });
  };

  // For handling the close model
  const onCancel = () => setState({ ...state, visible: false, orderModalVisible: false, view360ModalVisible: false });

  //To handle the add servicing  history
  const handleadd = (datas, form) => {
    datas.car = match.params.id;
    dispatch(addNewServicingHistorie(datas, form));
  };

  // To handle the edit servicing history
  const handleEdit = (id, datas) => dispatch(editServicingHistorie(id, datas));

  // To handle the delete servicing history
  const handleDelete = id => dispatch(deleteServicingHistorie(id));

  // To handle the delete servicing history
  const handleDeleteIssue = name => dispatch(deleteCarIssueImage(match.params.id, name));

  // To handle the car image ordering
  const handleOrderChange = (id, images) => dispatch(updateCarImageOrder(id, images));

  if (car && Object.keys(car).length > 0) {
    const {
      _id,
      name,
      isElectric,
      isNewCar,
      autonomy,
      price,
      discount,
      status,
      fuelType,
      details,
      exteriorColor,
      interiorColor,
      inspectionReport,
      newCarPriceFrom,
      model,
      bodyType,
      store,
      isHotDeal,
      vin,
      images,
      transmission,
      brand,
      mileage,
      enginePower,
      fuelConsumption,
      emissionCO2,
      weight,
      dimension,
      fullTankCapacity,
      audio,
      view360,
      noOfOwners,
      noOfSeats,
      speedRegulator,
      airCondition,
      bluetooth,
      cdPlayer,
      dateFirstUse,
      carIssues,
      reverseCamera,
      maisonMere,
      imported,
      tamperedOdometer,
      fiscalPower,
      engineSize,
      featured,
      keys,
      technicalVisitCertificate,
    } = car;
    return (
      <>
        <PageHeader ghost title="Car Details" />
        <Main>
          <Cards headless>
            <ProductDetailsWrapper>
              <div className="product-details-box">
                <Row gutter={30}>
                  <Col xs={24} lg={10}>
                    <div className="product-details-box__left pdbl">
                      <figure>
                        <img
                          src={
                            images
                              ? `${IMAGE_URL}/${JSON.parse(images)[state.index]}`
                              : require(`../../../static/img/noimage.png`)
                          }
                          alt={`img${_id}`}
                          height={300}
                          style={{ width: '100%', objectFit: 'contain' }}
                        />
                      </figure>
                      {images && (
                        <div className="pdbl__slider pdbs">
                          <Row gutter={5}>
                            {JSON.parse(images).map((value, index) => {
                              return (
                                <Col
                                  md={4}
                                  key={index}
                                  onClick={() => setState({ ...state, index })}
                                  style={{ marginBottom: 10 }}
                                >
                                  <div className="pdbl__image" key={index}>
                                    <figure>
                                      <img
                                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                        src={`${IMAGE_URL}/${value}`}
                                        alt={`img-${index}`}
                                      />
                                    </figure>
                                  </div>
                                </Col>
                              );
                            })}
                          </Row>
                        </div>
                      )}
                    </div>
                    <Button
                      onClick={() => showModal('order')}
                      size="small"
                      key="4"
                      style={{ backgroundColor: '#155BDC', color: '#fff', marginTop: 10, marginRight: 10 }}
                    >
                      Edit Image Order
                    </Button>
                    {view360 && (
                      <Button
                        onClick={() => showModal('360View')}
                        size="small"
                        key="5"
                        style={{ backgroundColor: '#155BDC', color: '#fff', marginTop: 10 }}
                      >
                        360 View
                      </Button>
                    )}
                  </Col>
                  <Col xs={24} lg={14}>
                    <Suspense
                      fallback={
                        <Cards headless>
                          <Skeleton active />
                        </Cards>
                      }
                    >
                      <div className="product-details-box__right pdbr">
                        <Heading className="pdbr__title" as="h4">
                          {name}
                        </Heading>
                        {/* BASIC DETAILS */}
                        <p
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '-10px',
                          }}
                        >
                          <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                            Price :{' '}
                          </span>
                          <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                            {new Intl.NumberFormat('fr-FR').format(price)} {process.env.REACT_APP_DEFAULT_CURRENCY}
                          </span>
                        </p>
                        <p
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '-10px',
                          }}
                        >
                          <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                            Discount :
                          </span>
                          <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                            {discount
                              ? `${new Intl.NumberFormat('fr-FR').format(discount)} ${
                                  process.env.REACT_APP_DEFAULT_CURRENCY
                                }`
                              : 'NA'}
                          </span>
                        </p>
                        {newCarPriceFrom && (
                          <p
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              marginTop: '-10px',
                            }}
                          >
                            <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                              New Car Price From :
                            </span>
                            <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                              {newCarPriceFrom
                                ? `${new Intl.NumberFormat('fr-FR').format(newCarPriceFrom)} ${
                                    process.env.REACT_APP_DEFAULT_CURRENCY
                                  }`
                                : 'NA'}
                            </span>
                          </p>
                        )}
                        {details &&
                          details[dataIndex] &&
                          details[dataIndex]?.description &&
                          details[dataIndex]?.description !== '' && (
                            <p
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginTop: '-10px',
                              }}
                            >
                              <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                                Description :
                              </span>
                              <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                                {details[dataIndex]?.description ?? 'NA'}
                              </span>
                            </p>
                          )}

                        {vin && (
                          <p
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              marginTop: '-10px',
                            }}
                          >
                            <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                              {dataIndex == 0 ? 'Numéro d’Identification du Véhicule' : 'Vehicle Identification Number'}
                            </span>
                            <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                              {vin ?? 'NA'}
                            </span>
                          </p>
                        )}
                        <p
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '-10px',
                          }}
                        >
                          <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                            Brand :
                          </span>
                          <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                            {brand?.brand ?? 'NA'}
                          </span>
                        </p>
                        <p
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '-10px',
                          }}
                        >
                          <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                            Featured :
                          </span>
                          <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                            {featured ? 'Yes' : 'No'}
                          </span>
                        </p>
                        <p
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '-10px',
                          }}
                        >
                          <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                            Is electric? :
                          </span>
                          <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                            {isElectric ? 'Yes' : 'No'}
                          </span>
                        </p>
                        <p
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '-10px',
                          }}
                        >
                          <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                            Is New? :
                          </span>
                          <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                            {isNewCar ? 'Yes' : 'No'}
                          </span>
                        </p>
                        <p
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '-10px',
                          }}
                        >
                          <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                            Model :
                          </span>
                          <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                            {model?.model ?? 'NA'}
                          </span>
                        </p>
                        <p
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '-10px',
                          }}
                        >
                          <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                            Body Type :
                          </span>
                          <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                            {bodyType?.details[dataIndex]?.bodyType ?? 'NA'}
                          </span>
                        </p>
                        <p
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '-10px',
                          }}
                        >
                          <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                            Exterior Color :
                          </span>
                          <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                            {exteriorColor?.details[dataIndex]?.color ?? 'NA'}
                          </span>
                        </p>
                        <p
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '-10px',
                          }}
                        >
                          <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                            Interior Color :
                          </span>
                          <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                            {interiorColor?.details[dataIndex]?.color ?? 'NA'}
                          </span>
                        </p>
                        <p
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '-10px',
                          }}
                        >
                          <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                            Status :
                          </span>
                          <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                            {status}
                          </span>
                        </p>
                        <p
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '-10px',
                          }}
                        >
                          <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                            Hot Deal :
                          </span>
                          <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                            {isHotDeal ? 'Yes' : 'No'}
                          </span>
                        </p>
                        {!isElectric ||
                          (!isNewCar && (
                            <p
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginTop: '-10px',
                              }}
                            >
                              <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                                Date First Use :
                              </span>
                              <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                                {dateFirstUse && moment(dateFirstUse).format('ddd D MMM YYYY')}
                              </span>
                            </p>
                          ))}
                        <p
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '-10px',
                          }}
                        >
                          <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                            Fuel Type :
                          </span>
                          <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                            {fuelType}
                          </span>
                        </p>

                        {/* FEATURES */}
                        <Heading className="pdbr__title" as="h4" style={{ marginTop: '10px' }}>
                          Features
                        </Heading>
                        <p
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '-10px',
                          }}
                        >
                          <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                            Transmission :
                          </span>
                          <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                            {transmission}
                          </span>
                        </p>
                        {isElectric ? (
                          <p
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              marginTop: '-10px',
                            }}
                          >
                            {' '}
                            <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                              {dataIndex == 0 ? 'Autonomie' : 'Autonomy'} :
                            </span>
                            <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                              {autonomy ? `${autonomy} KM` : 'NA'}
                            </span>
                          </p>
                        ) : (
                          <>
                            <p
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginTop: '-10px',
                              }}
                            >
                              <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                                {dataIndex == 0 ? 'Cylindrée' : 'Engine Size'} :
                              </span>
                              <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                                {engineSize ? `${engineSize} cm3` : 'NA'}
                              </span>
                            </p>
                            <p
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginTop: '-10px',
                              }}
                            >
                              <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                                Engine Power :
                              </span>
                              <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                                {enginePower ?? 'NA'}
                              </span>
                            </p>
                          </>
                        )}
                        <p
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '-10px',
                          }}
                        >
                          <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                            {dataIndex == 0 ? 'Puissance fiscale' : 'Fiscal Power'} :
                          </span>
                          <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                            {fiscalPower ? `${fiscalPower} hp` : 'NA'}
                          </span>
                        </p>
                        <p
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '-10px',
                          }}
                        >
                          <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                            Speed Regulator :
                          </span>
                          <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                            {speedRegulator ? 'Yes' : 'No'}
                          </span>
                        </p>
                        <p
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '-10px',
                          }}
                        >
                          <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                            {' '}
                            Air Condition :
                          </span>
                          <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                            {airCondition ? 'Yes' : 'No'}
                          </span>
                        </p>
                        <p
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '-10px',
                          }}
                        >
                          <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                            Bluetooth :
                          </span>
                          <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                            {bluetooth ? 'Yes' : 'No'}
                          </span>
                        </p>
                        <p
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '-10px',
                          }}
                        >
                          <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                            CD Player :
                          </span>
                          <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                            {cdPlayer ? 'Yes' : 'No'}
                          </span>
                        </p>
                        <p
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '-10px',
                          }}
                        >
                          <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                            Reverse Camera :
                          </span>
                          <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                            {reverseCamera ? 'Yes' : 'No'}
                          </span>
                        </p>
                        <p
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '-10px',
                          }}
                        >
                          <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                            Maison Mère :
                          </span>
                          <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                            {maisonMere ? 'Yes' : 'No'}
                          </span>
                        </p>
                        <p
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '-10px',
                          }}
                        >
                          <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                            {dataIndex == 0 ? 'Importée' : 'Imported'} :
                          </span>
                          <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                            {imported ? 'Yes' : 'No'}
                          </span>
                        </p>
                        <p
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '-10px',
                          }}
                        >
                          <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                            {dataIndex == 0 ? 'Compteur Truqué' : 'Tampered Odometer'} :
                          </span>
                          <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                            {tamperedOdometer ? 'Yes' : 'No'}
                          </span>
                        </p>
                        <p
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '-10px',
                          }}
                        >
                          <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                            Mileage :
                          </span>
                          <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                            {mileage ?? 'NA'} KM
                          </span>
                        </p>
                        <p
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '-10px',
                          }}
                        >
                          <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                            Fuel Consumption :
                          </span>
                          <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                            {fuelConsumption ?? 'NA'}
                          </span>
                        </p>
                        <p
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '-10px',
                          }}
                        >
                          <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                            Emission CO2 :
                          </span>
                          <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                            {emissionCO2 ?? 'NA'}
                          </span>
                        </p>
                        <p
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '-10px',
                          }}
                        >
                          <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                            Weight :
                          </span>
                          <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                            {weight} KG
                          </span>
                        </p>
                        <p
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '-10px',
                          }}
                        >
                          <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                            Dimension :
                          </span>
                          <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                            l={dimension?.length ? `${dimension.length}mm` : 'NA'}, b=
                            {dimension?.breadth ? `${dimension.breadth}mm` : 'NA'}, h=
                            {dimension?.height ? `${dimension.height}mm` : 'NA'}
                          </span>
                        </p>
                        <p
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '-10px',
                          }}
                        >
                          <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                            Fuel Tank Capacity :
                          </span>
                          <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                            {fullTankCapacity}L
                          </span>
                        </p>
                        <p
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '-10px',
                          }}
                        >
                          <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                            No of Owners :
                          </span>
                          <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                            {noOfOwners}
                          </span>
                        </p>
                        <p
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '-10px',
                          }}
                        >
                          <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                            Total Seats :
                          </span>
                          <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                            {noOfSeats}
                          </span>
                        </p>
                        {keys && (
                          <p
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              marginTop: '-10px',
                            }}
                          >
                            <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                              Keys :
                            </span>
                            <a
                              className="pdbr__brand-name"
                              style={{ flex: 1, alignItems: 'flex-start', color: 'skyblue' }}
                              href={`${IMAGE_URL}/${keys}`}
                              target="_blank"
                            >
                              Display Key File
                            </a>
                          </p>
                        )}

                        {technicalVisitCertificate && (
                          <p
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              marginTop: '-10px',
                            }}
                          >
                            <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                              {dataIndex == 0 ? 'Certificat de Visite Technique' : 'Technical Visit Certificate'} :
                            </span>
                            <a
                              className="pdbr__brand-name"
                              style={{ flex: 1, alignItems: 'flex-start', color: 'skyblue' }}
                              href={`${IMAGE_URL}/${technicalVisitCertificate}`}
                              target="_blank"
                            >
                              Display Technical Visit Certificate
                            </a>
                          </p>
                        )}

                        {/* Media */}
                        {audio && (
                          <div style={{ marginBottom: 10 }}>
                            <Heading className="pdbr__title" as="h4" style={{ marginTop: '10px' }}>
                              Media
                            </Heading>
                            {audio && <ReactAudioPlayer src={`${IMAGE_URL}/${audio}`} controls />}
                          </div>
                        )}

                        {store && (
                          <div className="product-details-box__right pdbr">
                            <Heading className="pdbr__title" as="h4">
                              Store Information
                            </Heading>
                            <p
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginTop: '-10px',
                              }}
                            >
                              <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                                Name :
                              </span>
                              <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                                {store.name}
                              </span>
                            </p>
                          </div>
                        )}
                      </div>
                      <br />
                    </Suspense>
                  </Col>
                </Row>
                {carIssues && JSON.parse(carIssues).length > 0 && (
                  <>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Heading className="pdbr__title" as="h4">
                        Car Issue
                      </Heading>
                    </div>
                    <CarIssueTable carIssues={carIssues} handleDeleteIssue={handleDeleteIssue} />
                    <br />
                  </>
                )}
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Heading className="pdbr__title" as="h4">
                    Servicing History
                  </Heading>
                  <Button
                    onClick={showModal}
                    size="small"
                    key="4"
                    style={{ backgroundColor: '#155BDC', color: '#fff' }}
                  >
                    <FeatherIcon icon="plus" size={14} />
                    Add Service History
                  </Button>
                </div>
                <ServicingHistoryTable histories={histories} handleDelete={handleDelete} handleEdit={handleEdit} />
              </div>
            </ProductDetailsWrapper>
          </Cards>
          <CreateServicingHistory visible={state.visible} handleAdd={handleadd} onCancel={onCancel} />
          <CarImageOrder
            visible={state.orderModalVisible}
            car={car}
            handleOrderChange={handleOrderChange}
            onCancel={onCancel}
          />
          {view360 && state.view360ModalVisible && (
            <View360 visible={state.view360ModalVisible} car360View={car.view360} name={car.name} onCancel={onCancel} />
          )}
        </Main>
      </>
    );
  } else {
    return isLoader ? (
      <Col xs={24}>
        <div className="spin">
          <Spin />
        </div>
      </Col>
    ) : (
      <h4 style={{ fontSize: 30, height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        Car Not Found
      </h4>
    );
  }
};

CarDetail.propTypes = {
  match: PropTypes.object,
};

export default CarDetail;
