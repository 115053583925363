// ==================================== COMPONENTS =========================================
// THIRD PARTY/ CORE COMPONENTS
import React, { useEffect, lazy, useState, Suspense, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Skeleton, Pagination, notification, Button as AntButton, Menu, Select } from 'antd';
import { Switch, Route, useRouteMatch, NavLink, Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import Pluralize from 'pluralize';

// USER'S COMPONENTS

import Cookies from 'js-cookie';
import { PaginationWrapper, TopToolBox } from './Style';
import BulkUpload from './overview/BulkUpload';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';
import { Button } from '../../components/buttons/buttons';
import { Cards } from '../../components/cards/frame/cards-frame';
import { getAllCars } from '../../redux/car/actionCreator';
import { getAllBrands, getCarBrands } from '../../redux/carbrand/actionCreator';
import { getAllBodyTypes } from '../../redux/carbodytype/actionCreator';
import { getAllServicingHistories } from '../../redux/servicing history/actionCreator';
import { getAllStores } from '../../redux/store/actionCreator';
import { getAllColors } from '../../redux/car color/actionCreator';
import axios from '../../redux/axios';
import { Dropdown } from '../../components/dropdown/dropdown';
import { getCarsPriceRange } from '../../redux/carPriceRange/actionCreator';
import { getHeaders } from '../../utils/common';

const Filters = lazy(() => import('./overview/Filters'));
const Grid = lazy(() => import('./overview/Grid'));

export const MODAL_TYPE = {
  carIssue: 'Car Issues',
  carImages: 'Car Images',
  carSounds: 'Car Sound',
  technicalVisitCertificate: 'Technical Visit Certificate',
  keys: 'Car Keys',
};

const Car = () => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  const {
    cars,
    totalCar,
    histories,
    bodyTypes,
    carBrands,
    stores,
    colors,
    currentPage,
    pageLimit,
    searchKeyword,
    route,
    brands,
    status,
    featured,
    isNewCar,
    minPrice,
    maxPrice,
  } = useSelector(state => {
    return {
      cars: state.car.cars,
      totalCar: state.car.total,
      bodyTypes: state.carBodyType.bodyTypes,
      carBrands: state.carBrand.carBrands,
      histories: state.servicingHistory.histories,
      stores: state.store.stores,
      colors: state.carColor.colors,
      currentPage: state.car.currentPage,
      pageLimit: state.car.pageLimit,
      brands: state.car.brands,
      status: state.car.status,
      featured: state.car.featured,
      searchKeyword: state.headerSearch.searchKeyword,
      route: state.headerSearch.path,
      isNewCar: state.car.isNewCar,
      minPrice: state.carPriceRange.minPrice,
      maxPrice: state.carPriceRange.maxPrice,
    };
  });
  const [checkSearchKeyword, setCheckSearchKeyword] = useState(searchKeyword);
  const [excelLoading, setExcelLoading] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [bodyTypesOptions, setBodyTypesOptions] = useState();

  const [state, setState] = useState({
    filterBy: {
      statusSort: status ?? null,
      searchData: '',
      featured: featured === 'true',
      brands: brands.length > 0 ? JSON.parse(brands) : null,
      isNewCar,
      fuelType: '',
      bodyType: '',
      price: 0,
    },
    active: 'active',
    visible: false,
    current: currentPage,
    pageSize: pageLimit,
    editModalVisible: false,
    deleteModalVisible: false,
    draft: false,
    online: false,
    sold: false,
  });

  const isShowClearBtn = Object.values(state.filterBy).some(
    value => value !== null && value !== undefined && value !== '' && value !== false,
  );

  useEffect(() => {
    const bodyTypesSelectOptions = bodyTypes?.map(items => ({
      label: `${items.details[0].bodyType}`,
      value: items.slug,
    }));
    setBodyTypesOptions(bodyTypesSelectOptions);
  }, [bodyTypes]);

  useEffect(() => {
    dispatch(getCarsPriceRange());
    dispatch(getAllBrands('?all=true'));
    if (carBrands.length === 0) dispatch(getCarBrands());
    if (bodyTypes.length === 0) getAllBodyTypes(dispatch);
    if (stores.length === 0) dispatch(getAllStores());
    if (histories?.length === 0) getAllServicingHistories(dispatch);
    if (colors?.length === 0) getAllColors(dispatch);
  }, []);

  useEffect(() => {
    // FOR FILTERING
    let filters = `page=${state.current}&limit=${state.pageSize || 9}&`;
    Object.keys(state.filterBy).map(key => {
      if (state.filterBy[key]) {
        if (key === 'statusSort') filters += `status=${state.filterBy[key]}&`;
        else if (key === 'price') filters += `maxPrice=${state.filterBy[key]}&`;
        else if (key === 'brands') filters += `brands=${JSON.stringify(state.filterBy[key])}&`;
        else if (key === 'isNewCar') filters += `isNewCar=${state.filterBy[key]}&`;
        else if (key === 'searchData') filters += `search=${state.filterBy[key]}&`;
        else if (key === 'featured' && state.filterBy[key]) filters += `featured=${state.filterBy[key]}&`;
        else if (key === 'fuelType' && state.filterBy[key]) filters += `fuelType=${state.filterBy[key]}&`;
        else if (key === 'bodyType' && state.filterBy[key]) filters += `bodyType=${state.filterBy[key]}&`;
      }
    });
    if (filters) dispatch(getAllCars(filters));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (checkSearchKeyword !== searchKeyword) {
      if (route === 'cars') {
        setState({ ...state, filterBy: { ...state.filterBy, searchData: searchKeyword }, current: 1, pageSize: 8 });
        setCheckSearchKeyword(searchKeyword);
      }
    }
  }, [searchKeyword]);

  // For sorting
  const sorting = (type, value) => {
    if (type === 'statusSort') setState({ ...state, filterBy: { ...state.filterBy, statusSort: value }, current: 1 });
    else if (type === 'price') setState({ ...state, filterBy: { ...state.filterBy, price: value }, current: 1 });
    else if (type === 'brands')
      setState({ ...state, filterBy: { ...state.filterBy, brands: value.length > 0 ? value : null }, current: 1 });
    else if (type === 'featured') setState({ ...state, filterBy: { ...state.filterBy, featured: value }, current: 1 });
    else if (type === 'newSort') setState({ ...state, filterBy: { ...state.filterBy, isNewCar: value }, current: 1 });
    else if (type === 'fuelType') setState({ ...state, filterBy: { ...state.filterBy, fuelType: value }, current: 1 });
    else if (type === 'bodyType') setState({ ...state, filterBy: { ...state.filterBy, bodyType: value }, current: 1 });
  };

  const onHandleChange = (current, pageSize) => {
    setState({ ...state, current, pageSize });
  };

  // for bulk upload popup
  const bulkUpload = () => {
    setIsUpload(!isUpload);
  };

  const handleGenerateExcel = async lang => {
    try {
      setExcelLoading(true);
      const response = await axios.get(`/cars/excel-file?language=${lang}`, {
        headers: getHeaders(),
        responseType: 'blob',
      });

      if (response.status === 200) {
        // Ensure the request was successful
        const contentDisposition = response.headers['content-disposition'];
        const filenameMatch = contentDisposition ? contentDisposition.match(/filename="?(.+)"?/) : null;
        const fileName = filenameMatch ? filenameMatch[1].replace(/['"]/g, '') : 'Cars Export.xlsx';
        // Create a blob from the response data
        const blob = new Blob([response.data], {
          type: response.headers['content-Type'],
        });
        const blobUrl = URL.createObjectURL(blob);

        // Create a link element and trigger the download
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = fileName;
        link.target = '_blank';
        link.click();

        // Clean up the URL object
        URL.revokeObjectURL(blobUrl);
        setExcelLoading(false);
      } else {
        setExcelLoading(false);
        notification.error({ message: response.data.message });
      }
    } catch (error) {
      notification.error({ message: 'Error generating the file' });
      setExcelLoading(false);
    }
  };

  const menu = (
    <>
      <Link
        to="#"
        onClick={() => {
          handleGenerateExcel('en');
        }}
      >
        <span>English</span>
      </Link>
      <Link
        to="#"
        onClick={() => {
          handleGenerateExcel('fr');
        }}
      >
        <span>French</span>
      </Link>
    </>
  );

  const clearFilters = () => {
    setState(prevState => ({
      ...prevState,
      filterBy: {
        statusSort: null,
        searchData: '',
        featured: null,
        brands: null,
        isNewCar: null,
        fuelType: '',
        bodyType: '',
      },
      current: 1,
      pageSize: 9,
    }));
  };

  return (
    <>
      <PageHeader
        ghost
        title="Car"
        subTitle={
          totalCar > 0 && (
            <span className="title-counter">
              {totalCar} {Pluralize('Car', totalCar)}{' '}
            </span>
          )
        }
        buttons={[
          // isShowClearBtn && (
          //   <div key="0" className="">
          //     <Button
          //       size="small"
          //       type="ghost"
          //       style={{ backgroundColor: 'transparent', border: '1px solid #d7deea', color: '#454545' }}
          //       onClick={clearFilters}
          //     >
          //       <div style={{ display: 'flex', alignItems: 'center' }}>
          //         <FeatherIcon icon="x-circle" size={14} />
          //         <span>Clear Filters</span>
          //       </div>
          //     </Button>
          //   </div>
          // ),
          <div key="1" className="page-header-actions">
            <Dropdown placement="bottomLeft" content={menu} action={['hover']}>
              <Button
                size="default"
                type="primary"
                key="1"
                style={{ backgroundColor: '#089227', borderColor: '#089227', color: '#fff' }}
                loading={excelLoading}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FeatherIcon icon="download-cloud" size={16} />
                  <span>Export Excel</span>
                </div>
              </Button>
            </Dropdown>
          </div>,
          <div key="2" className="page-header-actions">
            <NavLink to="../admin/cars/add">
              <Button size="small" key="4" style={{ backgroundColor: '#155BDC', color: '#fff' }}>
                <FeatherIcon icon="plus" size={14} />
                Add New
              </Button>
            </NavLink>
            {/* <Button onClick={bulkUpload} size="small" key="4" style={{ backgroundColor: '#3561a9', color: '#fff' }}>
              <FeatherIcon icon="upload" size={14} />
              Bulk Upload
            </Button> */}
          </div>,
        ]}
      />
      <Main>
        <Row gutter={30}>
          <Col className="product-sidebar-col" xxl={5} xl={7} lg={7} md={10} xs={24}>
            <Suspense
              fallback={
                <Cards headless>
                  <Skeleton paragraph={{ rows: 22 }} active />
                </Cards>
              }
            >
              <Filters
                sorting={sorting}
                brands={carBrands}
                searchBrands={brands}
                minPrice={minPrice}
                maxPrice={maxPrice}
                filterBy={state.filterBy}
                onFilterChange={newFilter => {
                  setState(prevState => ({
                    ...prevState,
                    filterBy: newFilter,
                  }));
                }}
              />
            </Suspense>
          </Col>
          <Col className="product-content-col" xxl={19} lg={17} md={14} xs={24}>
            <TopToolBox>
              <Row gutter={[8, 8]} className="filter-select-wrapper">
                <Col xxl={4} lg={4} md={8} xs={12}>
                  <Select
                    placeholder="Status"
                    dropdownStyle={{ zIndex: 998 }}
                    onChange={value => sorting('statusSort', value)}
                    value={state.filterBy.statusSort || null}
                    allowClear
                  >
                    <Select.Option value="online">Online</Select.Option>
                    <Select.Option value="sold">Sold</Select.Option>
                    <Select.Option value="draft">Draft</Select.Option>
                  </Select>
                </Col>
                <Col xxl={4} lg={4} md={8} xs={12}>
                  <Select
                    placeholder="Condition"
                    dropdownStyle={{ zIndex: 998 }}
                    onChange={value => sorting('newSort', value)}
                    value={state.filterBy.isNewCar}
                    allowClear
                  >
                    <Select.Option value="true">New</Select.Option>
                    <Select.Option value="false">Old</Select.Option>
                  </Select>
                </Col>
                <Col xxl={4} lg={4} md={8} xs={12}>
                  <Select
                    placeholder="Featured"
                    dropdownStyle={{ zIndex: 998 }}
                    onChange={value => sorting('featured', value)}
                    value={state.filterBy.featured || null}
                    allowClear
                  >
                    <Select.Option value="true">Featured</Select.Option>
                  </Select>
                </Col>
                <Col xxl={4} lg={4} md={8} xs={12}>
                  <Select
                    placeholder="Fuel Type"
                    dropdownStyle={{ zIndex: 998 }}
                    onChange={value => sorting('fuelType', value)}
                    value={state.filterBy.fuelType || null}
                    allowClear
                  >
                    <Select.Option value="ev">EV</Select.Option>
                    <Select.Option value="hybrid">Hybrid</Select.Option>
                    <Select.Option value="phev">PHEV</Select.Option>
                    <Select.Option value="petrol">Petrol</Select.Option>
                    <Select.Option value="diesel">Diesel</Select.Option>
                    <Select.Option value="carburant">Carburant</Select.Option>
                    <Select.Option value="other">Other</Select.Option>
                  </Select>
                </Col>
                <Col xxl={4} lg={4} md={8} xs={12}>
                  <Select
                    placeholder="Body Type"
                    dropdownStyle={{ zIndex: 998 }}
                    onChange={value => sorting('bodyType', value)}
                    value={state.filterBy.bodyType || null}
                    allowClear
                  >
                    {bodyTypesOptions &&
                      bodyTypesOptions.map(item => (
                        <Select.Option value={item.value} key={item.value}>
                          {item.label}
                        </Select.Option>
                      ))}
                  </Select>
                </Col>
                <Col xxl={4} lg={4} md={8} xs={12}>
                  <Button
                    size="large"
                    type="ghost"
                    className="filter-reset-btn"
                    onClick={() => isShowClearBtn && clearFilters()}
                  >
                    Reset Filters
                  </Button>
                </Col>
              </Row>
            </TopToolBox>

            <Switch>
              <Suspense>
                <Route exact path={path} component={Grid} />
                <Row>
                  <Col xs={24} className="pb-30">
                    <PaginationWrapper style={{ marginTop: 10 }}>
                      {cars?.length ? (
                        <Pagination
                          onChange={onHandleChange}
                          current={currentPage}
                          defaultPageSize={pageLimit}
                          total={totalCar}
                          showSizeChanger
                          showTotal={(total, range) => `${range[0]}-${range[1]} of ${totalCar} items`}
                        />
                      ) : null}
                    </PaginationWrapper>
                  </Col>
                </Row>
              </Suspense>
            </Switch>
          </Col>
        </Row>
      </Main>
      <BulkUpload visible={isUpload} onCancel={bulkUpload} />
    </>
  );
};

export default Car;
