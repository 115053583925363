/* eslint-disable no-underscore-dangle */
import { Col, Divider, Row, Switch, Button } from 'antd';
import { Form, Formik } from 'formik';
import propTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { carInitialFormData, carValidationSchema } from './car.schema';
import CarIssueForm from './CarIssueForm';
import CarImagesForm from './CarImagesForm';
import CarFieldForm from './CarFieldForm';
import { BasicFormWrapper } from '../../styled';
import FormInput, { INPUT_TYPE } from '../../../components/input/input';
import {
  COUNTRY_TOP_LEVEL_DOMAIN,
  DEFAULT_LANG,
  LANGUAGE_BY_DOMAIN,
  getTopLevelDomain,
} from '../../../utils/constants';
import FormSelect from '../../../components/select/FormSelect';
import { getAllBrands } from '../../../redux/carbrand/actionCreator';
import { getAllModelsByBrand } from '../../../redux/carmodel/actionCreator';
import { getAllBodyTypes } from '../../../redux/carbodytype/actionCreator';
import DivBreak from '../../../components/divBreak/DivBreak';
import { getAllColors } from '../../../redux/car color/actionCreator';
import { getAllStores } from '../../../redux/store/actionCreator';
import FormUpload from '../../../components/upload/FormUpload';
import { Modal } from '../../../components/modals/antd-modals';
import { addNewCar, editCar } from '../../../redux/car/actionCreator';
import { MODAL_TYPE } from '../Car';

export const FEATURES_RADIO = [
  'isHotDeal',
  'lowPrice',
  'featured',
  'clean',
  'airCondition',
  'speedRegulator',
  'bluetooth',
  'cdPlayer',
  'reverseCamera',
  'gpsNavigation',
  'parkAssist',
  'startAndStop',
  'airBag',
  'abs',
  'computer',
  'rims',
  'sunRoof',
  'electricMirror',
  'electricWindow',
  'sequential',
  'maisonMere',
  'imported',
  'tamperedOdometer',
];

const CarForm = props => {
  const { formData, setFormData } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const { brandsData, carLoading, modelsData, bodyTypesData, colorsData, storesData } = useSelector(state => {
    return {
      brandsData: state.carBrand.brands,
      modelsData: state.carModel.modelsByBrand,
      bodyTypesData: state.carBodyType.bodyTypes,
      colorsData: state.carColor.colors,
      storesData: state.store.stores,
      vinDataData: state.car.vinData,
      carLoading: state.car.loading,
    };
  });

  const [flags, setFlags] = useState({
    isMultiLangFlag: false,
    isElectricFlag: false,
    isNewCarFlag: false,
  });
  const [showCarIssueModal, setShowCarIssueModal] = useState(false);
  const [showModal, setShowModal] = useState({
    show: false,
    type: null,
  });
  const [keyForCommonModal, setKeyForCommonModal] = useState({ name: null, value: null });

  useEffect(() => {
    dispatch(getAllBrands('?all=true'));
    getAllBodyTypes(dispatch);
    getAllColors(dispatch);
    dispatch(getAllStores());
  }, []);

  useEffect(() => {
    setFlags({
      ...flags,
      isMultiLangFlag: formData?.isMultiLang.includes('true'),
      isElectricFlag: formData?.isElectric.includes('true'),
      isNewCarFlag: formData?.isNewCar.includes('true'),
    });
  }, [formData]);

  function getYesNoRadioOpt() {
    return [
      {
        value: 'true',
        label: 'Yes',
      },
      {
        value: 'false',
        label: 'No',
      },
    ];
  }

  function getStatusDropdownOpt() {
    return [
      {
        value: 'draft',
        key: `draft`,
        label: `${' '}Draft`,
      },
      {
        value: 'online',
        key: `online`,
        label: `${' '}Online`,
      },
      {
        value: 'sold',
        key: `sold`,
        label: `${' '}Sold`,
      },
    ];
  }

  function getBrandDropdownOpt() {
    const dropdownOpt = brandsData?.map(brand => ({
      value: brand?._id ?? '',
      key: `${brand?.brand}`,
      label: `${brand?.brand}`,
    }));
    return dropdownOpt ?? [];
  }

  function getModelDropdownOpt() {
    const dropdownOpt = modelsData?.map(model => ({
      value: model?._id ?? '',
      key: `${model?.model}`,
      label: `${model?.model}`,
    }));
    return dropdownOpt ?? [];
  }

  function handleFetchModelByBrandId(id) {
    dispatch(getAllModelsByBrand(id));
  }

  const years = [];
  // eslint-disable-next-line no-plusplus
  for (let i = new Date().getFullYear(); i >= 2000; i--) years.push(i);

  function getModelYearDropdownOpt() {
    const dropdownOpt = years?.map(year => ({
      value: year ?? '',
      key: `${year}`,
      label: `${year}`,
    }));
    dropdownOpt.push({ value: 'other', key: 'Other', label: 'Other' });
    return dropdownOpt ?? [];
  }

  function getCarBodyTypeDropdownOpt() {
    const dropdownOpt = bodyTypesData?.map(bodyType => ({
      value: bodyType?._id ?? '',
      key: `${bodyType.details[0]?.bodyType}`,
      label: `${bodyType.details[0]?.bodyType}`,
    }));
    return dropdownOpt ?? [];
  }

  function getTransmissionTypeDropdownOpt() {
    return [
      {
        value: 'manual',
        key: `manual`,
        label: `${' '}Manual`,
      },
      {
        value: 'automatic',
        key: `automatic`,
        label: `${' '}Automatic`,
      },
    ];
  }

  function getFuelTypeDropdownOpt() {
    return [
      {
        value: 'ev',
        key: `Electric`,
        label: `${' '}Electric`,
      },
      {
        value: 'hybrid',
        key: `Hybrid`,
        label: `${' '}Hybrid`,
      },
      {
        value: 'phev',
        key: `PHEV`,
        label: `${' '}PHEV`,
      },
      {
        value: 'petrol',
        key: `Petrol`,
        label: `${' '}Petrol`,
      },
      {
        value: 'diesel',
        key: `Diesel`,
        label: `${' '}Diesel`,
      },
      {
        value: 'carburant',
        key: `Carburant`,
        label: `${' '}Carburant`,
      },
      {
        value: 'other',
        key: `Other`,
        label: `${' '}Other`,
      },
    ];
  }

  function getColorsDropdownOpt() {
    const dropdownOpt = colorsData?.map(color => ({
      value: color?._id ?? '',
      key: `${color.details[0]?.color}`,
      label: `${color.details[0]?.color}`,
    }));
    return dropdownOpt ?? [];
  }

  function getStoreDropdownOpt() {
    const dropdownOpt = storesData?.map(store => ({
      value: store?._id ?? '',
      key: `${store?.name}`,
      label: `${store?.name}`,
    }));
    return dropdownOpt ?? [];
  }

  function getConditionDropdownOpt() {
    return [
      {
        value: 'excellent',
        key: `excellent`,
        label: `${' '}Excellent`,
      },
      {
        value: 'good',
        key: `good`,
        label: `${' '}Good`,
      },
      {
        value: 'average',
        key: `average`,
        label: `${' '}Average`,
      },
      {
        value: 'damaged',
        key: `damaged`,
        label: `${' '}Damaged`,
      },
    ];
  }

  function getSteeringPositionOpt() {
    return [
      {
        value: 'left',
        key: `left`,
        label: `${' '}Left`,
      },
      {
        value: 'right',
        key: `right`,
        label: `${' '}Right`,
      }
    ];
  }

  const handleResetForm = resetForm => {
    resetForm();
    setFormData({ ...carInitialFormData });
  };

  const toggleModalByType = modalType => {
    setShowCarIssueModal(!showCarIssueModal);
    setShowModal({
      show: !showModal.show,
      type: modalType,
    });
  };

  const cancelModal = () => {
    setShowModal({
      show: false,
      type: null,
    });
    setKeyForCommonModal({ name: null, value: null });
  };

  async function handleFormSubmit(requestData, resetForm) {
    try {
      const transformedDescs = [];
      const descriptionFields = [];
      LANGUAGE_BY_DOMAIN.forEach(langItem => {
        if (requestData.isMultiLang === 'true' || langItem[1] === DEFAULT_LANG.value) {
          descriptionFields.push(`description${langItem[1]}`);
          transformedDescs.push({
            lang: langItem[1],
            description: requestData[`description${langItem[1]}`],
          });
        }
      });

      const formValue = new FormData();

      Object.keys(requestData).forEach(key => {
        let filteredKey = ['images', 'audio', 'keys', 'technicalVisitCertificate', 'isMultiLang', 'carIssueArr'];
        filteredKey = [...filteredKey, ...descriptionFields];
        if (!filteredKey.includes(key)) {
          if (FEATURES_RADIO.includes(key)) {
            if (requestData[key] !== null) {
              formValue.append(key, requestData[key]);
            } else formValue.append(key, 'false');
            return;
          }
          if (typeof requestData[key] === 'number') {
            formValue.append(key, requestData[key]);
            return;
          }
          if (requestData[key]) {
            if (requestData.isNewCar === 'true' && key === 'discount') return;
            if (requestData.isNewCar === 'true' && key === 'dateFirstUse') return;
            formValue.append(key, requestData[key]);
          } else {
            formValue.append(key, requestData[key]);
          }
        }
      });

      if (requestData?.images) {
        requestData.images.forEach(item => {
          if (item?.originFileObj) formValue.append('images', item?.originFileObj);
        });
      }

      if (requestData?.audio && requestData?.audio[0]?.originFileObj)
        formValue.append('audio', requestData?.audio[0]?.originFileObj);

      if (requestData?.keys && requestData?.keys[0]?.originFileObj)
        formValue.append('keys', requestData?.keys[0]?.originFileObj);

      if (requestData?.technicalVisitCertificate && requestData?.technicalVisitCertificate[0]?.originFileObj)
        formValue.append('technicalVisitCertificate', requestData?.technicalVisitCertificate[0]?.originFileObj);

      if (requestData?.carIssueArr) {
        const carIssuesCaptions = [];
        requestData.carIssueArr.forEach(item => {
          if (item?.issueCaption) carIssuesCaptions.push(item?.issueCaption);
          if (item?.issueImage && item?.issueImage?.length > 0) {
            if (item?.issueImage[0]?.originFileObj) formValue.append('carIssues', item?.issueImage[0]?.originFileObj);
          }
          return null;
        });
        formValue.append('carIssuesCaptions', JSON.stringify(carIssuesCaptions));
      }

      formValue.append('details', JSON.stringify(transformedDescs));

      const res = await dispatch(params?.id ? editCar(params?.id, formValue, history) : addNewCar(formValue, history));

      // eslint-disable-next-line no-underscore-dangle
      if (res?.data?.status) {
        resetForm();
        setFormData({ ...carInitialFormData });
        getAllColors(dispatch);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }

  const renderModalComponent = (values, setFieldValue, errors) => {
    switch (showModal.type) {
      case MODAL_TYPE.carIssue:
        return (
          <CarIssueForm
            errors={errors}
            values={values}
            setFieldValue={setFieldValue}
            cancelModal={cancelModal}
            formData={formData}
          />
        );
      case MODAL_TYPE.carImages:
        return (
          <CarImagesForm values={values} setFieldValue={setFieldValue} cancelModal={cancelModal} formData={formData} />
        );
      default:
        return (
          <CarFieldForm
            values={values}
            setFieldValue={setFieldValue}
            keyNameAndValue={keyForCommonModal}
            cancelModal={cancelModal}
            formData={formData}
          />
        );
    }
  };

  return (
    <>
      <Formik
        initialValues={formData}
        validationSchema={carValidationSchema(flags)}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(false);
          // eslint-disable-next-line no-use-before-define
          handleFormSubmit(values, resetForm);
        }}
        enableReinitialize
      >
        {({ values, errors, touched, handleChange, setFieldValue, resetForm }) => {
          return (
            <>
              <Modal
                type="primary"
                title={showModal.type}
                visible={showModal.show}
                width={800}
                onCancel={cancelModal}
                footer={null}
              >
                <div className="body-type-modal">{renderModalComponent(values, setFieldValue, errors)}</div>
              </Modal>

              <Form>
                <BasicFormWrapper>
                  {/* is electric switcher, is new switcher and language switcher */}
                  <div className="switch-container">
                    {[
                      { label: 'Is Electric?', value: 'isElectric' },
                      { label: 'Is New?', value: 'isNewCar' },
                      { label: 'Translate', value: 'isMultiLang' },
                    ].map(item => (
                      <div>
                        <h4>{item.label}</h4>
                        <Switch
                          checked={values[item.value] === 'true'}
                          onChange={checked => {
                            setFieldValue(item.value, String(checked));
                            if (item.value === 'isMultiLang') {
                              setFlags({
                                ...flags,
                                isMultiLangFlag: checked,
                              });
                            }
                            if (item.value === 'isElectric') {
                              setFlags({
                                ...flags,
                                isElectricFlag: checked,
                              });
                              if (checked) {
                                setFieldValue('transmission', null);
                                setFieldValue('fuelType', null);
                                setFieldValue('engineSize', '');
                                setFieldValue('transmission', null);
                                setFieldValue('fuelType', null);
                                setFieldValue('enginePower', '');
                                setFieldValue('fullTankCapacity', '');
                                setFieldValue('fuelConsumption', '');
                                setFieldValue('emissionCO2', '');
                              } else {
                                setFieldValue('autonomy', '');
                              }
                            } else if (item.value === 'isNewCar') {
                              setFlags({
                                ...flags,
                                isNewCarFlag: checked,
                              });
                              if (checked) {
                                setFieldValue('dateFirstUse', '');
                                setFieldValue('featured', 'false');
                                setFieldValue('newCarPriceFrom', '');
                                setFieldValue('discount', '');
                                setFieldValue('discountPercent', '');
                                setFieldValue('dateFirstUse', '');
                              }
                            }
                          }}
                        />
                      </div>
                    ))}
                  </div>
                  <DivBreak />

                  {/* vin, price, status, newCarPriceFrom, discountPercent and mileage */}
                  <div className="add-product-block">
                    <Row gutter={[32, 8]}>
                      <Col xs={24} sm={12} md={8}>
                        <FormInput
                          type={INPUT_TYPE.search}
                          name="vin"
                          label="Vehicle Identification Number"
                          value={values.vin}
                          handleChange={handleChange}
                          handleSearch={searchElem => {
                            return searchElem;
                          }}
                          placeholder="Vehicle Identification Number"
                          errors={errors}
                          touched={touched}
                          isRequired={false}
                        />
                      </Col>
                      <Col xs={24} sm={12} md={8}>
                        <FormInput
                          type={INPUT_TYPE.number}
                          name="price"
                          label={values.isNewCar === 'true' ? 'Price From' : 'Price'}
                          value={values.price}
                          handleChange={handleChange}
                          placeholder="Price"
                          errors={errors}
                          touched={touched}
                        />
                      </Col>
                      <Col xs={24} sm={12} md={8}>
                        <FormSelect
                          name="status"
                          label="Status"
                          value={values.status}
                          options={getStatusDropdownOpt() ?? []}
                          handleChange={value => {
                            setFieldValue('status', value);
                          }}
                          placeholder="Select"
                          errors={errors}
                          touched={touched}
                        />
                      </Col>
                      {values.isNewCar !== 'true' && (
                        <>
                          <Col xs={24} sm={12} md={8}>
                            <FormInput
                              type={INPUT_TYPE.number}
                              name="newCarPriceFrom"
                              label="New Car Price From"
                              value={values.newCarPriceFrom}
                              handleChange={handleChange}
                              placeholder="New Car Price From"
                              errors={errors}
                              touched={touched}
                              isRequired={false}
                            />
                          </Col>
                          <Col xs={24} sm={12} md={8}>
                            <FormInput
                              type={INPUT_TYPE.number}
                              name="discount"
                              label="Discount"
                              value={values.discount}
                              handleChange={handleChange}
                              placeholder="Discount amount"
                              errors={errors}
                              touched={touched}
                              isRequired={false}
                            />
                          </Col>
                        </>
                      )}
                      <Col xs={24} sm={12} md={8}>
                        <FormInput
                          type={INPUT_TYPE.number}
                          name="mileage"
                          label="Mileage"
                          value={values.mileage}
                          handleChange={handleChange}
                          placeholder="Mileage"
                          errors={errors}
                          touched={touched}
                        />
                      </Col>
                    </Row>
                  </div>
                  <DivBreak />

                  {/* description default  */}
                  <div className="add-product-block">
                    <Row>
                      <Col xs={24}>
                        <FormInput
                          type={INPUT_TYPE.textarea}
                          name={`description${DEFAULT_LANG.value}`}
                          label={`Description (${DEFAULT_LANG.label})`}
                          value={values[`description${DEFAULT_LANG.value}`]}
                          handleChange={handleChange}
                          placeholder="Description"
                          errors={errors}
                          touched={touched}
                        />
                      </Col>
                    </Row>
                  </div>
                  <DivBreak />

                  {/* description dynamic  */}
                  {values.isMultiLang === 'true' &&
                    LANGUAGE_BY_DOMAIN.filter(item => DEFAULT_LANG.value !== item[1]).map(([label, value], index) => {
                      return (
                        <>
                          <div className="add-product-block" key={index}>
                            <Row>
                              <Col xs={24}>
                                <FormInput
                                  type={INPUT_TYPE.textarea}
                                  name={`description${value}`}
                                  label={`Description (${label})`}
                                  value={values[`description${value}`]}
                                  handleChange={handleChange}
                                  placeholder="Description"
                                  errors={errors}
                                  touched={touched}
                                />
                              </Col>
                            </Row>
                          </div>
                          <DivBreak />
                        </>
                      );
                    })}

                  {/* is hot deal, low price, featured and clean */}
                  <div className="add-product-block">
                    <Row gutter={[16, 16]}>
                      {[
                        { label: 'Hot Deal?', value: 'isHotDeal' },
                        { label: 'Low Price?', value: 'lowPrice' },
                        { label: 'Featured?', value: 'featured' },
                        { label: 'Clean?', value: 'clean' },
                      ].map(item => {
                        if (item.value === 'featured' && values.isNewCar === 'true') return <></>;
                        return (
                          <Col xs={12} md={6}>
                            <FormInput
                              type={INPUT_TYPE.radio}
                              name={item.value}
                              label={item.label}
                              value={values[item.value]}
                              handleChange={handleChange}
                              radioOptions={getYesNoRadioOpt()}
                              errors={errors}
                              touched={touched}
                              isRequired={false}
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                  <DivBreak />

                  {/* brand, model, modelYear, (if not electric then: bodyType, engineSize, (if not new then: dateFirstUse)), transmission, fuelType, noOfKeys, noOfSeats, weight, noOfOwners, exteriorColor, interiorColor, autonomy/enginePower, store, condition, fullTankCapacity, fuelConsumption, emissionCO2, length, breadth, height and fiscalPower */}
                  <div className="add-product-block">
                    <Row gutter={[32, 8]}>
                      <Col xs={24} sm={12} md={8}>
                        <FormSelect
                          name="brand"
                          label="Car Brand"
                          value={values.brand}
                          options={getBrandDropdownOpt() ?? []}
                          handleChange={value => {
                            handleFetchModelByBrandId(value);
                            setFieldValue('brand', value);
                            setFieldValue('model', null);
                          }}
                          placeholder="Select"
                          errors={errors}
                          touched={touched}
                        />
                      </Col>
                      <Col xs={24} sm={12} md={8}>
                        <FormSelect
                          name="model"
                          label="Car Model"
                          value={values.model}
                          options={getModelDropdownOpt() ?? []}
                          handleChange={value => {
                            setFieldValue('model', value);
                          }}
                          placeholder="Select"
                          errors={errors}
                          touched={touched}
                        />
                      </Col>
                      <Col xs={24} sm={12} md={8}>
                        <FormSelect
                          name="modelYear"
                          label="Car Year"
                          value={values.modelYear}
                          options={getModelYearDropdownOpt() ?? []}
                          handleChange={value => {
                            setFieldValue('modelYear', value);
                          }}
                          placeholder="Select"
                          errors={errors}
                          touched={touched}
                        />
                      </Col>
                      <Col xs={24} sm={12} md={8}>
                        <FormSelect
                          name="bodyType"
                          label="Car Body Type"
                          value={values.bodyType}
                          options={getCarBodyTypeDropdownOpt() ?? []}
                          handleChange={value => {
                            setFieldValue('bodyType', value);
                          }}
                          placeholder="Select"
                          errors={errors}
                          touched={touched}
                        />
                      </Col>
                      {values.isElectric === 'false' && (
                        <>
                          <Col xs={24} sm={12} md={8}>
                            <FormInput
                              type={INPUT_TYPE.number}
                              name="engineSize"
                              label="Engine size"
                              value={values.engineSize}
                              handleChange={handleChange}
                              placeholder="Engine size"
                              errors={errors}
                              touched={touched}
                              isRequired={false}
                            />
                          </Col>
                        </>
                      )}
                      {values.isNewCar === 'false' && (
                        <>
                          <Col xs={24} sm={12} md={8}>
                            <FormInput
                              type={INPUT_TYPE.date}
                              name="dateFirstUse"
                              label="Date First Use"
                              value={values.dateFirstUse}
                              handleChange={handleChange}
                              placeholder="Date First Use"
                              errors={errors}
                              touched={touched}
                            />
                          </Col>
                        </>
                      )}
                      <Col xs={24} sm={12} md={8}>
                        <FormInput
                          type={INPUT_TYPE.number}
                          name="noOfOwners"
                          label="Number of owners"
                          value={values.noOfOwners}
                          handleChange={handleChange}
                          placeholder="Number of owners"
                          errors={errors}
                          touched={touched}
                        />
                      </Col>
                      <Col xs={24} sm={12} md={8}>
                        <FormInput
                          type={INPUT_TYPE.number}
                          name="weight"
                          label="Weight"
                          value={values.weight}
                          handleChange={handleChange}
                          placeholder="Weight"
                          errors={errors}
                          touched={touched}
                          isRequired={false}
                        />
                      </Col>
                      <Col xs={24} sm={12} md={8}>
                        <FormInput
                          type={INPUT_TYPE.number}
                          name="noOfKeys"
                          label="No of Keys"
                          value={values.noOfKeys}
                          handleChange={handleChange}
                          placeholder="No of Keys"
                          errors={errors}
                          touched={touched}
                          isRequired={false}
                        />
                      </Col>
                      <Col xs={24} sm={12} md={8}>
                        <FormInput
                          type={INPUT_TYPE.number}
                          name="noOfSeats"
                          label="No of Seats"
                          value={values.noOfSeats}
                          handleChange={handleChange}
                          placeholder="No of Seats"
                          errors={errors}
                          touched={touched}
                          isRequired={false}
                        />
                      </Col>
                      {values.isElectric === 'false' && (
                        <>
                          <Col xs={24} sm={12} md={8}>
                            <FormSelect
                              name="fuelType"
                              label="Fuel Type"
                              value={values.fuelType}
                              options={getFuelTypeDropdownOpt() ?? []}
                              handleChange={value => {
                                setFieldValue('fuelType', value);
                              }}
                              placeholder="Select"
                              errors={errors}
                              touched={touched}
                            />
                          </Col>

                          <Col xs={24} sm={12} md={8}>
                            <FormSelect
                              name="transmission"
                              label="Transmission Type"
                              value={values.transmission}
                              options={getTransmissionTypeDropdownOpt() ?? []}
                              handleChange={value => {
                                setFieldValue('transmission', value);
                              }}
                              placeholder="Select"
                              errors={errors}
                              touched={touched}
                            />
                          </Col>
                        </>
                      )}
                      <Col xs={24} sm={12} md={8}>
                        <FormSelect
                          name="steeringPosition"
                          label="Steering Position"
                          value={values.steeringPosition}
                          options={getSteeringPositionOpt() ?? []}
                          handleChange={value => {
                            setFieldValue('steeringPosition', value);
                          }}
                          placeholder="Select"
                          errors={errors}
                          touched={touched}
                          isRequired={true}
                        />
                      </Col>
                      <Col xs={24} sm={12} md={8}>
                        <FormSelect
                          name="exteriorColor"
                          label="Exterior Color"
                          value={values.exteriorColor}
                          options={getColorsDropdownOpt() ?? []}
                          handleChange={value => {
                            setFieldValue('exteriorColor', value);
                          }}
                          placeholder="Select"
                          errors={errors}
                          touched={touched}
                        />
                      </Col>
                      <Col xs={24} sm={12} md={8}>
                        <FormSelect
                          name="interiorColor"
                          label="Interior Color"
                          value={values.interiorColor}
                          options={getColorsDropdownOpt() ?? []}
                          handleChange={value => {
                            setFieldValue('interiorColor', value);
                          }}
                          placeholder="Select"
                          errors={errors}
                          touched={touched}
                        />
                      </Col>
                      <Col xs={24} sm={12} md={8}>
                        <FormInput
                          type={values.isElectric === 'true' ? INPUT_TYPE.number : INPUT_TYPE.text}
                          name={values.isElectric === 'true' ? 'autonomy' : 'enginePower'}
                          label={values.isElectric === 'true' ? 'Autonomy' : 'Engine Power'}
                          value={values.isElectric === 'true' ? values.autonomy : values.enginePower}
                          handleChange={handleChange}
                          placeholder={values.isElectric === 'true' ? 'Autonomy' : 'Engine Power'}
                          errors={errors}
                          touched={touched}
                          suffix={values.isElectric === 'true' ? 'KM' : null}
                          isRequired={false}
                        />
                      </Col>
                      <Col xs={24} sm={12} md={8}>
                        <FormSelect
                          name="store"
                          label="Store"
                          value={values.store}
                          options={getStoreDropdownOpt() ?? []}
                          handleChange={value => {
                            setFieldValue('store', value);
                          }}
                          placeholder="Select"
                          errors={errors}
                          touched={touched}
                        />
                      </Col>
                      <Col xs={24} sm={12} md={8}>
                        <FormSelect
                          name="condition"
                          label="Condition"
                          value={values.condition}
                          options={getConditionDropdownOpt() ?? []}
                          handleChange={value => {
                            setFieldValue('condition', value);
                          }}
                          placeholder="Select"
                          errors={errors}
                          touched={touched}
                        />
                      </Col>
                      {values.isElectric === 'false' && (
                        <>
                          <Col xs={24} sm={12} md={8}>
                            <FormInput
                              type={INPUT_TYPE.number}
                              name="fullTankCapacity"
                              label="Fuel Tank Capacity"
                              value={values.fullTankCapacity}
                              handleChange={handleChange}
                              placeholder="Full Tank Capacity"
                              errors={errors}
                              touched={touched}
                              suffix="L"
                              isRequired={false}
                            />
                          </Col>

                          <Col xs={24} sm={12} md={8}>
                            <FormInput
                              name="fuelConsumption"
                              label="Fuel Consumption"
                              value={values.fuelConsumption}
                              handleChange={handleChange}
                              placeholder="61L/100KM"
                              errors={errors}
                              touched={touched}
                              isRequired={false}
                            />
                          </Col>

                          <Col xs={24} sm={12} md={8}>
                            <FormInput
                              name="emissionCO2"
                              label="Emission CO2"
                              value={values.emissionCO2}
                              handleChange={handleChange}
                              placeholder="141 G/KM"
                              errors={errors}
                              touched={touched}
                              isRequired={false}
                            />
                          </Col>
                        </>
                      )}
                      <Col xs={24} sm={12} md={8}>
                        <FormInput
                          type={INPUT_TYPE.number}
                          name="length"
                          label="Length"
                          value={values.length}
                          handleChange={handleChange}
                          placeholder="Length"
                          errors={errors}
                          touched={touched}
                          suffix="mm"
                          isRequired={false}
                        />
                      </Col>
                      <Col xs={24} sm={12} md={8}>
                        <FormInput
                          type={INPUT_TYPE.number}
                          name="breadth"
                          label="Breadth"
                          value={values.breadth}
                          handleChange={handleChange}
                          placeholder="Breadth"
                          errors={errors}
                          touched={touched}
                          suffix="mm"
                          isRequired={false}
                        />
                      </Col>
                      <Col xs={24} sm={12} md={8}>
                        <FormInput
                          type={INPUT_TYPE.number}
                          name="height"
                          label="Height"
                          value={values.height}
                          handleChange={handleChange}
                          placeholder="Height"
                          errors={errors}
                          touched={touched}
                          suffix="mm"
                          isRequired={false}
                        />
                      </Col>
                      <Col xs={24} sm={12} md={8}>
                        <FormInput
                          type={INPUT_TYPE.number}
                          name="fiscalPower"
                          label="Fiscal Power"
                          value={values.fiscalPower}
                          handleChange={handleChange}
                          placeholder="Fiscal Power"
                          errors={errors}
                          touched={touched}
                          isRequired={false}
                        />
                      </Col>
                      {getTopLevelDomain() === COUNTRY_TOP_LEVEL_DOMAIN.ma && (
                        <Col xs={24} sm={12} md={8}>
                          <FormInput
                            type={INPUT_TYPE.number}
                            name="warranty"
                            label="Warranty (In Months)"
                            value={values.warranty}
                            handleChange={handleChange}
                            placeholder="Warranty"
                            errors={errors}
                            touched={touched}
                            isRequired={false}
                          />
                        </Col>
                      )}
                    </Row>
                  </div>
                  <DivBreak />

                  <Divider />

                  <h3>Features</h3>
                  {/* speedRegulator, airCondition, bluetooth and cdPlayer */}
                  <div className="add-product-block">
                    <Row gutter={[16, 32]}>
                      {[
                        { label: 'Speed Regulator', value: 'speedRegulator' },
                        { label: 'Air Condition', value: 'airCondition' },
                        { label: 'Bluetooth', value: 'bluetooth' },
                        { label: 'CD Player', value: 'cdPlayer' },
                      ].map(item => (
                        <Col xs={12} md={6}>
                          <FormInput
                            type={INPUT_TYPE.radio}
                            name={item.value}
                            label={item.label}
                            value={values[item.value]}
                            handleChange={handleChange}
                            radioOptions={getYesNoRadioOpt()}
                            errors={errors}
                            touched={touched}
                            isRequired={false}
                          />
                        </Col>
                      ))}
                    </Row>
                  </div>
                  <DivBreak />

                  {/* reverseCamera, gpsNavigation, parkAssist and startAndStop */}
                  <div className="add-product-block">
                    <Row gutter={[16, 32]}>
                      {[
                        { label: 'Reverse Camera', value: 'reverseCamera' },
                        { label: 'GPS Navigation', value: 'gpsNavigation' },
                        { label: 'Park Assist', value: 'parkAssist' },
                        { label: 'Start And Stop', value: 'startAndStop' },
                      ].map(item => (
                        <Col xs={12} md={6}>
                          <FormInput
                            type={INPUT_TYPE.radio}
                            name={item.value}
                            label={item.label}
                            value={values[item.value]}
                            handleChange={handleChange}
                            radioOptions={getYesNoRadioOpt()}
                            errors={errors}
                            touched={touched}
                            isRequired={false}
                          />
                        </Col>
                      ))}
                    </Row>
                  </div>
                  <DivBreak />

                  {/* airBag, abs, computer and rims */}
                  <div className="add-product-block">
                    <Row gutter={[16, 32]}>
                      {[
                        { label: 'Air Bag', value: 'airBag' },
                        { label: 'ABS', value: 'abs' },
                        { label: 'Computer', value: 'computer' },
                        { label: 'Rims', value: 'rims' },
                      ].map(item => (
                        <Col xs={12} md={6}>
                          <FormInput
                            type={INPUT_TYPE.radio}
                            name={item.value}
                            label={item.label}
                            value={values[item.value]}
                            handleChange={handleChange}
                            radioOptions={getYesNoRadioOpt()}
                            errors={errors}
                            touched={touched}
                            isRequired={false}
                          />
                        </Col>
                      ))}
                    </Row>
                  </div>
                  <DivBreak />

                  {/* sunRoof, electricMirror, electricWindow and sequential */}
                  <div className="add-product-block">
                    <Row gutter={[16, 32]}>
                      {[
                        { label: 'Sun Roof', value: 'sunRoof' },
                        { label: 'Electric Mirror', value: 'electricMirror' },
                        { label: 'Electric Window', value: 'electricWindow' },
                        { label: 'Sequential', value: 'sequential' },
                      ].map(item => (
                        <Col xs={12} md={6}>
                          <FormInput
                            type={INPUT_TYPE.radio}
                            name={item.value}
                            label={item.label}
                            value={values[item.value]}
                            handleChange={handleChange}
                            radioOptions={getYesNoRadioOpt()}
                            errors={errors}
                            touched={touched}
                            isRequired={false}
                          />
                        </Col>
                      ))}
                    </Row>
                  </div>
                  <DivBreak />

                  {/* maisonMere, imported and tamperedOdometer */}
                  <div className="add-product-block">
                    <Row gutter={[16, 32]}>
                      {[
                        { label: 'Maison Mere', value: 'maisonMere' },
                        { label: 'Imported', value: 'imported' },
                        { label: 'Tampered Odometer', value: 'tamperedOdometer' },
                      ].map(item => (
                        <Col xs={12} md={6}>
                          <FormInput
                            type={INPUT_TYPE.radio}
                            name={item.value}
                            label={item.label}
                            value={values[item.value]}
                            handleChange={handleChange}
                            radioOptions={getYesNoRadioOpt()}
                            errors={errors}
                            touched={touched}
                            isRequired={false}
                          />
                        </Col>
                      ))}
                    </Row>
                  </div>
                  <DivBreak />

                  {/* images, audio, technicalVisitCertificate, keys, carIssues and view360 */}
                  <div className="add-product-block">
                    <Row gutter={[32, 8]}>
                      <Col xs={24} sm={12} md={8}>
                        <FormInput label="Car Images" isRequired={false}>
                          <Button
                            type="transparent"
                            onClick={e => {
                              e.stopPropagation();
                              toggleModalByType(MODAL_TYPE.carImages);
                            }}
                            icon={<PlusOutlined />}
                            style={{ height: '40px', width: '100%' }}
                          >
                            Add Car Images
                          </Button>
                        </FormInput>
                      </Col>
                      <Col xs={24} sm={12} md={8}>
                        <FormInput label="Car Issue" isRequired={false}>
                          <Button
                            type="transparent"
                            onClick={e => {
                              e.stopPropagation();
                              toggleModalByType(MODAL_TYPE.carIssue);
                            }}
                            icon={<PlusOutlined />}
                            style={{ height: '40px', width: '100%' }}
                          >
                            Add Car Issues
                          </Button>
                        </FormInput>
                      </Col>
                      <Col xs={24} sm={12} md={8}>
                        <FormInput
                          name="view360"
                          label="360 View Embed Code"
                          value={values.view360}
                          handleChange={handleChange}
                          placeholder="360 View Embed Code"
                          errors={errors}
                          touched={touched}
                          isRequired={false}
                        />
                      </Col>
                      <Col xs={24} sm={12} md={8}>
                        {values._id ? (
                          <FormInput label="Car Sound" isRequired={false}>
                            <Button
                              type="transparent"
                              onClick={e => {
                                e.stopPropagation();
                                toggleModalByType(MODAL_TYPE.carSounds);
                                setKeyForCommonModal({ name: MODAL_TYPE.carSounds, value: 'audio' });
                              }}
                              icon={<PlusOutlined />}
                              style={{ height: '40px', width: '100%' }}
                            >
                              Add Car Sounds
                            </Button>
                          </FormInput>
                        ) : (
                          <FormUpload
                            name="audio"
                            label="Car Sound"
                            value={values.audio}
                            handleChange={info => {
                              const fileList = [...info.fileList].slice(-1);
                              setFieldValue('audio', fileList.length > 0 ? [fileList[0]] : null);
                            }}
                            accept="audio/mp3,audio/*;capture=microphone"
                            errors={errors}
                            touched={touched}
                            isRequired={false}
                          />
                        )}
                      </Col>
                      <Col xs={24} sm={12} md={8}>
                        {values._id ? (
                          <FormInput label="Technical Visit Certificate" isRequired={false}>
                            <Button
                              type="transparent"
                              onClick={e => {
                                e.stopPropagation();
                                toggleModalByType(MODAL_TYPE.technicalVisitCertificate);
                                setKeyForCommonModal({
                                  name: MODAL_TYPE.technicalVisitCertificate,
                                  value: 'technicalVisitCertificate',
                                });
                              }}
                              icon={<PlusOutlined />}
                              style={{ height: '40px', width: '100%' }}
                            >
                              Add Technical Visit Certificate
                            </Button>
                          </FormInput>
                        ) : (
                          <FormUpload
                            name="technicalVisitCertificate"
                            label="Technical Visit Certificate"
                            value={values.technicalVisitCertificate}
                            handleChange={info => {
                              const fileList = [...info.fileList].slice(-1);
                              setFieldValue('technicalVisitCertificate', fileList.length > 0 ? [fileList[0]] : null);
                            }}
                            accept="image/*"
                            errors={errors}
                            touched={touched}
                            isRequired={false}
                          />
                        )}
                      </Col>
                      <Col xs={24} sm={12} md={8}>
                        {values._id ? (
                          <FormInput label="Car Keys" isRequired={false}>
                            <Button
                              type="transparent"
                              onClick={e => {
                                e.stopPropagation();
                                toggleModalByType(MODAL_TYPE.keys);
                                setKeyForCommonModal({
                                  name: MODAL_TYPE.keys,
                                  value: 'keys',
                                });
                              }}
                              icon={<PlusOutlined />}
                              style={{ height: '40px', width: '100%' }}
                            >
                              Add Car Keys
                            </Button>
                          </FormInput>
                        ) : (
                          <FormUpload
                            name="keys"
                            label="Car Keys"
                            value={values.keys}
                            handleChange={info => {
                              const fileList = [...info.fileList].slice(-1);
                              setFieldValue('keys', fileList.length > 0 ? [fileList[0]] : null);
                            }}
                            accept="image/*"
                            errors={errors}
                            touched={touched}
                            isRequired={false}
                          />
                        )}
                      </Col>
                    </Row>
                  </div>

                  <DivBreak />

                  <Row justify="end" align="middle">
                    <Col>
                      <Button
                        style={{
                          backgroundColor: '#155BDC',
                          color: '#fff',
                          marginRight: 5,
                          height: '40px',
                          fontWeight: '500',
                        }}
                        htmlType="submit"
                        loading={carLoading}
                      >
                        {params?.id ? 'Update Car' : 'Add New Car'}
                      </Button>
                      <Button
                        size="default"
                        type="white"
                        key="back"
                        outlined
                        onClick={() => handleResetForm(resetForm)}
                      >
                        Reset
                      </Button>
                    </Col>
                  </Row>
                </BasicFormWrapper>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

CarForm.propTypes = {
  formData: propTypes.object.isRequired,
  setFormData: propTypes.func.isRequired,
};

export default CarForm;
