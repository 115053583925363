// ==================================== COMPONENTS =========================================
// THIRD PARTY/ CORE COMPONENTS
import React from 'react';
import propTypes from 'prop-types';
import SortableList, { SortableItem } from "react-easy-sort";
import { Avatar, makeStyles } from "@material-ui/core";
import arrayMove from "array-move";

// USER'S COMPONENTS
import { Button } from '../../../components/buttons/buttons';
import { Modal } from '../../../components/modals/antd-modals';
import { BasicFormWrapper } from '../../styled';
import './sortStyle.css';
import { IMAGE_URL } from '../../../utils/constants';
import { useEffect } from 'react';

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexWrap: "wrap",
    userSelect: "none"
  },
  item: {
    position: "relative",
    flexShrink: 0,
    display: "flex",
    margin: 8,
    cursor: "grab",
    userSelect: "none",
    boxShadow: "0px 6px 6px -3px rgba(0, 0, 0, 0.2)",
    borderRadius: "100%"
  },
  image: {
    width: 150,
    height: 150,
    pointerEvents: "none"
  },
  dragged: {
    boxShadow:
      "0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)",
    "& button": {
      opacity: 0
    }
  }
});

const CarImageOrder = ({ visible, onCancel, car, handleOrderChange }) => {
  const classes = useStyles();
  const [items, setItems] = React.useState([]);

  useEffect(() => {
    const newItems = [];
    if (car.images) {
      const images = JSON.parse(car.images);
      images.forEach(image => {
        newItems.push({ name: image, image: `${IMAGE_URL}/${image}` })
      });
      setItems(newItems);
    }
  }, []);


  // To handle the delete 
  const handleSubmit = () => {
    const images = items.map(item => {
      return (item.image).split(IMAGE_URL)[1].replaceAll('/', '');
    })
    if (images.length > 0)
      handleOrderChange(car._id, JSON.stringify(images));
    onCancel();
  }

  const onSortEnd = (oldIndex, newIndex) => {
    setItems((array) => arrayMove(array, oldIndex, newIndex));
  };

  return car ? (
    <Modal
      type={'primary'}
      title="Ordering Car Images"
      visible={visible}
      footer={null}
      onCancel={onCancel}
    >
      <div className="car-modal">
        <BasicFormWrapper>
          <SortableList
            onSortEnd={onSortEnd}
            className={classes.root}
            draggedItemClassName={classes.dragged}
          >
            {items.map(({ name, image }) => (
              <SortableItem key={name}>
                <div className={classes.item}>
                  <Avatar
                    className={classes.image}
                    alt={name}
                    src={image}
                    imgProps={{ draggable: false }}
                  />
                </div>
              </SortableItem>
            ))}
          </SortableList>
          <div style={{ textAlign: 'center' }}>
            <Button onClick={handleSubmit} size="small" key="4" style={{ backgroundColor: '#155BDC', color: '#fff', marginTop: 10 }}>
              Update Image Order
            </Button>
          </div>
        </BasicFormWrapper>
      </div>
    </Modal>
  ) : null;
};

CarImageOrder.propTypes = {
  visible: propTypes.bool.isRequired,
  onCancel: propTypes.func.isRequired,
};

export default CarImageOrder;